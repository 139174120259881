import { useGtm } from '@gtm-support/vue-gtm'
import dayjs from 'dayjs'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { ApiError, FanVestorDetail } from '@/generated/api'
import i18n from '@/i18n'
import { UserRole } from '@/modules/auth/types'
import { FANVESTOR } from '@/modules/fanvestor/routes'

import { CLUB_PROFILE, EMAIL_VERIFICATION, LOGIN } from './routes'
import { useAuthStore } from './store'

export const authMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const loginRoute = {
    name: LOGIN,
    query: { redirect_to: to.fullPath !== '/' ? to.fullPath : undefined },
  }

  const store = useAuthStore()

  try {
    const user = await store.fetchMe()

    if (to.name === EMAIL_VERIFICATION) {
      return await store
        .verifyEmail(to.query.verification_code as string)
        .then(() => {
          useGtm()?.trackEvent({
            event: 'accountRegistered',
            category: 'account',
            label: `User ${store.user?.id} registered.`,
            userId: store.user?.id,
            userAge: dayjs().diff(dayjs((store.user as FanVestorDetail)?.birthdate), 'year'),
            userCountry: (store.user as FanVestorDetail)?.country,
            userLanguage: i18n.global.t(`common.settings.${store.user?.language}`, {
              locale: 'en',
            }),
          })

          return next(
            typeof to.query.redirect_path === 'string'
              ? { path: to.query.redirect_path }
              : { name: FANVESTOR }
          )
        })
        .catch(() => next({ name: LOGIN }))
    }

    if (!to.meta.auth) {
      return next()
    }

    if (user && to.name === LOGIN) {
      return next({ path: from.path })
    }

    if (
      (user?.role === UserRole.ClubUser && !!to.meta.authFanvestor) ||
      (user?.role === UserRole.FanVestor && !!to.meta.authClub) ||
      (user?.role === UserRole.ClubUser &&
        +to.params.clubId !== user.club_id &&
        to.name !== CLUB_PROFILE)
    ) {
      return next({ path: from.path })
    }

    if (!user) {
      store.logout()
      return next(loginRoute)
    }
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.status >= 500) {
        return next('/500')
      }
      if ([401, 403].includes(e?.status)) {
        store.logout()
        return next(loginRoute)
      }
    }
    throw e
  }
  return next()
}
